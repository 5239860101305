import React from "react"

import Layout from "@src/components/Layout"
import CommunityCallout from "@src/components/CommunityCallout"
import Callout from "@src/components/Callout"

import OG from "@src/icons/og/OG_CMS.jpeg"
import Cms from "@src/icons/screenshots/cms.png"
import ApiDefinition from "@src/icons/screenshots/api-definition.png"
import CollaborateTogether from "@src/icons/screenshots/collaborate-together.png"
import IntegrationsIcon from "@src/icons/v2/integrations-grid.svg"

import Splash from "./components/Splash"
import ImageRow from "./components/ImageRow"
import Row from "../components/Row"

const IndexPage = ({ location }) => {
  return (
    <Layout
      title="No waiting! Instantly start adding your data."
      description="Don't wait for frontend to integrate with backend to start adding data. Our Built-in CMS hooks up directly to your API so you can start adding data as soon as you build your APIs."
      url="https://canonic.dev/features/cms"
      location={location}
      ogImage={OG}
    >
      <Splash
        title="No waiting! Instantly start adding your data."
        subtitle="Don't wait for frontend to integrate with backend. Our Built-in CMS hooks up directly to your API so you can start adding data as soon as you build your APIs."
        screenshot={Cms}
      />
      <Row>
        <ImageRow
          title="Adapts to your API definition "
          subtitle="Adaptive layout of the CMS helps you get most out of it as it automatically changes view depending on the type of API definition you've defined."
          screenshot={ApiDefinition}
        />
      </Row>
      <Row>
        <ImageRow
          title="No more Scripts for uploading data"
          subtitle="With the import feature, import data directly into your database without the hassle of writing scripts."
          icon={IntegrationsIcon}
          reverse
        />
      </Row>
      <Row>
        <ImageRow
          title="Single Hub, Collaborate together to deliver content"
          subtitle="Invite your team and deliver quality content to your users straight from the CMS. All of your data will be accessible from a touch point saving you the hassle of managing code or multiple services."
          screenshot={CollaborateTogether}
        />
      </Row>
      <Callout location={location} title="cms" />
      <CommunityCallout />
    </Layout>
  )
}

export default IndexPage
